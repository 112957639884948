export const getPointTypes = async () => {
  const API_URL = process.env.REACT_APP_POINTS_TYPE_URL;

  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(API_URL, requestOptions);

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("no response", error);
  }
};
