import React, { useEffect, useState } from "react";
import ButtonShowMore from "./ButtonShowMore";
import ComboBoxChecklist from "./ComboBoxChecklist";
import { RefreshCcw, X } from "feather-icons-react/build/IconComponents";
import ButtonFilters from "./ButtonFilters";

const Filters = ({
  isPointTypesLoaded,
  isAllServicesLoaded,
  sortedClientServices,
  setClientServicesCheck,
  pointTypesWithCheck,
  setPointTypesCheck,
  additionalServicesWithCheck,
  setAdditionalServicesCheck,
  filtersControllerWithCheck,
  setFilterControllerCheck,

  resetFilters,

  map,
  setCenter,
  setIsFiltersMenuOpened,
}) => {
  const setChecksAndUpdateCenter = (setCheckFunction, valueToSet) => {
    const newCenter = map.getCenter();
    setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
    setCheckFunction(valueToSet);
  };

  const atLeastOneFilterActivatedFunction = () => {
    let flag = false;
    if (sortedClientServices) {
      flag = sortedClientServices?.some((service) => service.checked);
      if (flag) return true;
    }
    if (pointTypesWithCheck) {
      flag = pointTypesWithCheck?.some((point) => point.checked);
      if (flag) return true;
    }
    if (filtersControllerWithCheck) {
      flag = filtersControllerWithCheck?.some(
        (controller) => controller.checked
      );
      if (flag) return true;
    }
    if (additionalServicesWithCheck) {
      flag = additionalServicesWithCheck?.some((service) => service.checked);
      if (flag) return true;
    }
    return false;
  };

  const atLeastOneFilterActivated = atLeastOneFilterActivatedFunction();

  return (
    <div className="container-filters container ">
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-center gap-4">
        <div className=" py-cel-1">
          {isPointTypesLoaded ? (
            <ComboBoxChecklist
              text={"Tipos de Punto"}
              array={pointTypesWithCheck?.filter((pt) => pt.visibility)}
              onClickItem={setPointTypesCheck}
            />
          ) : (
            <div className="filter-combobox lazyload-fast h-40px" />
          )}
        </div>

        <div className="py-cel-1">
          {isAllServicesLoaded ? (
            <ComboBoxChecklist
              text={"Seleccionar servicios"}
              array={sortedClientServices}
              onClickItem={setClientServicesCheck}
            />
          ) : (
            <div className="filter-combobox lazyload-fast h-100 h-40px"></div>
          )}
        </div>

        <div>
          <ButtonFilters setIsFilterMenuOpened={setIsFiltersMenuOpened} />
        </div>

        {atLeastOneFilterActivated && (
          <div>
            <button
              className="btn btn-options-white details fs-14px fw-700 "
              onClick={() => resetFilters()}
            >
              <div className="px-2 hide-on-desktop-and-tablet">
                <RefreshCcw size={16} />
              </div>
              Limpiar Filtros
            </button>
          </div>
        )}
      </div>
      <div
        className={
          (atLeastOneFilterActivated ? "pt-2 " : "pt-1 ") +
          " d-flex flex-wrap gap-2"
        }
      >
        {pointTypesWithCheck?.map((pointType) => {
          if (pointType.checked) {
            return (
              <ActiveFilterLabel
                key={pointType.code}
                text={pointType.description}
                code={pointType.code}
                setIsFilterCheck={setPointTypesCheck}
              />
            );
          }
        })}
        {sortedClientServices?.map(
          (service) =>
            service.checked && (
              <ActiveFilterLabel
                key={service.code}
                text={service.description}
                code={service.code}
                setIsFilterCheck={setClientServicesCheck}
              />
            )
        )}

        {additionalServicesWithCheck?.map(
          (service) =>
            service.checked && (
              <ActiveFilterLabel
                key={service.code}
                text={service.description}
                code={service.code}
                setIsFilterCheck={setAdditionalServicesCheck}
              />
            )
        )}

        {filtersControllerWithCheck?.map(
          (filter) =>
            filter.checked && (
              <ActiveFilterLabel
                key={filter.code}
                text={filter.filter_name}
                code={filter.code}
                setIsFilterCheck={setFilterControllerCheck}
              />
            )
        )}
      </div>
    </div>
  );
};

const ActiveFilterLabel = ({ text, code, setIsFilterCheck }) => {
  return (
    <div className="active-filter-label">
      <span>{text}</span>
      <div
        className="cursor-pointer"
        onClick={() => setIsFilterCheck(false, code)}
      >
        <X size={24} />
      </div>
    </div>
  );
};

export default Filters;
