import React, { useState, useEffect, useCallback } from "react";
import {
  checkLocationPermission,
  createPoint,
  getDistancesAndSortAgencies,
  getEmptyListbox,
  getFilteredAgenciesV2,
  getParamsFromUrl,
  setActualGeolocation,
} from "../utilities/utilities";
import { getPickupPoints } from "../services/getPickupPoints";
import Banner from "./Banner";
import SharePopUp from "./SharePopUp";
import ListBox from "./ListBox";
import MapComponent from "./Map";
import SearchBar from "./Search";
import LazyLoadListBox from "./LazyLoadListBox";
import { getPointTypes } from "../services/getPointTypes";
import Filters from "./Filters";
import { getFiltersController } from "../services/getFiltersController";
import { getAllClientServices } from "../services/getAllClientServices";
import SideFilters from "./SideFilters";
import { getAdditionalServices } from "../services/getAdditionalServices";
import ButtonFilters from "./ButtonFilters";
import ListBoxDetailsCellPhone from "./ListBoxDetailsCellPhone";

const GeneralContainer = () => {
  const params = getParamsFromUrl();
  const selectedId = params.id;
  const isBannerEnabled = params.isBannerEnabled;

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Limpiar el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [map, setMap] = useState(null);

  const [isFiltersMenuOpened, setIsFiltersMenuOpened] = useState(false);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isPointTypesLoaded, setIsPointTypesLoaded] = useState(false);
  const [isFilterControllerLoaded, setIsFilterControllerLoaded] =
    useState(false);
  const [isAllClientServicesLoaded, setIsAllClientServicesLoaded] =
    useState(false);
  const [isAdditionalServicesLoaded, setIsAdditionalServicesLoaded] =
    useState(false);

  const [agencies, setAgencies] = useState();
  const [agenciesInsideBoundaries, setAgenciesInsideBoundaries] = useState();
  const [topAgency, setTopAgency] = useState();
  const [selectedAgency, setSelectedAgency] = useState();

  let isTopAgencyFiltered = false;

  const [listboxDetailsCellphoneOpen, setListboxDetailsCellphoneOpen] =
    useState({ state: false, agency: null });

  const [additionalServices, setAdditionalServices] = useState(null);
  const [filtersController, setFiltersController] = useState(null);

  const [pointTypesWithCheck, setPointTypesWithCheck] = useState(null);
  const [allClientServicesWithCheck, setAllClientServicesWithCheck] =
    useState(null);
  const [additionalServicesWithCheck, setAdditionalServicesWithCheck] =
    useState();
  const [filtersControllerWithCheck, setFiltersControllerWithCheck] =
    useState();

  const [actualFilterPointTypeCode, setActualFilterPointTypeCode] =
    useState(-1);
  const [actualFilterClientService, setActualFilterClientService] =
    useState(-1);

  const [selectedMarkerSize, setSelectedMarkerSize] = useState(60);

  const [selectedSwitchButtonValue, setSelectedSwitchButtonValue] =
    useState("option-location");

  const [idActualInfoWindow, setIdActualInfoWindow] = useState(-1);

  const [isSharePopUpOpen, setIsSharePopUpOpen] = useState(false);
  const [infoToShare, setInfoToShare] = useState({
    id: "no info",
    title: "no info",
    address: "no info",
  });

  const [center, setCenter] = useState({
    lat: -33.4488897,
    lng: -70.6692655,
  });
  const [actualLocation, setActualLocation] = useState(center);
  const [zoom, setZoom] = useState(14);

  const [searchBarText, setSearchBarText] = useState("");

  useEffect(() => {
    if (!isPointTypesLoaded) {
      getPointTypes().then((pointTypesResponse) => {
        setIsPointTypesLoaded(true);

        const auxPointTypes = pointTypesResponse.point_types?.map(
          (pointType) => ({ ...pointType, checked: false })
        );
        setPointTypesWithCheck(auxPointTypes);
      });
    }
    if (!isDataLoaded) {
      let newCenter = center;
      checkLocationPermission().then((permission) => {
        if (permission === "granted") {
          newCenter = setActualGeolocation(
            setActualLocation,
            setCenter,
            center
          );
        }
      });

      getPickupPoints().then((agencies) => {
        const sortedAgencies = getDistancesAndSortAgencies(agencies, newCenter);

        setAgencies(sortedAgencies);
        setIsDataLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    if (!isFilterControllerLoaded) {
      getFiltersController().then((filtersControllerResponse) => {
        setIsFilterControllerLoaded(true);
        setFiltersController(filtersControllerResponse);

        const auxFiltersController =
          filtersControllerResponse.filter_controllers?.map((filter) => ({
            ...filter,
            checked: false,
          }));

        setFiltersControllerWithCheck(auxFiltersController);
      });
    }
  }, []);

  useEffect(() => {
    if (!isAllClientServicesLoaded) {
      getAllClientServices().then((allClientServicesResponse) => {
        setIsAllClientServicesLoaded(true);

        const auxClientServices = allClientServicesResponse.client_services
          ?.sort((a, b) => a.description.localeCompare(b.description))
          ?.map((service) => ({ ...service, checked: false }));
        setAllClientServicesWithCheck(auxClientServices);
      });
    }
  }, []);

  useEffect(() => {
    if (!isAdditionalServicesLoaded) {
      getAdditionalServices().then((additionalServicesResponse) => {
        setIsAdditionalServicesLoaded(true);
        setAdditionalServices(additionalServicesResponse);

        const auxServices = additionalServicesResponse.additional_services?.map(
          (service) => ({ ...service, checked: false })
        );
        setAdditionalServicesWithCheck(auxServices);
      });
    }
  }, []);

  useEffect(() => {
    if (agencies) {
      if (selectedId) {
        handleBluexMarkerClick(selectedId);
      }
    }
  }, [isDataLoaded]);

  useEffect(() => {
    if (!agencies) return;
    const sortedAgencies = getDistancesAndSortAgencies(
      agencies.agencies,
      actualLocation
    );
    setAgencies(sortedAgencies);
    setTopAgency(null);
    const listBox = document.querySelector(".list-box-container");
    listBox.scrollTop = 0;
  }, [actualLocation]);

  const handleOpenPopUp = (infoToShare) => {
    setIsSharePopUpOpen(true);
    setInfoToShare(infoToShare);
  };

  const handleClosePopUp = () => {
    setIsSharePopUpOpen(false);
    setInfoToShare({ id: "no info", title: "no info", address: "no info" });
  };

  const handleClickListboxTitle = (id) => {
    const auxAgency = agencies.agencies.find((agency) => {
      if (agency.agencyId === id && screenSize.width < 768) {
        setListboxDetailsCellphoneOpen({ state: true, agency: agency });
      }
      return agency.agencyId === id;
    });
    setSelectedMarkerSize(selectedMarkerSize === 60 ? 59 : 60);
    setSelectedAgency(auxAgency);

    const newCenter = {
      lat: auxAgency.address.geolocation.latitude,
      lng: auxAgency.address.geolocation.longitude,
    };
    setCenter(newCenter);
    setZoom(zoom === 16 ? 15 : 16);
  };

  const handleBluexMarkerClick = (id) => {
    setSelectedMarkerSize(selectedMarkerSize === 60 ? 59 : 60);

    const auxAgency = agencies.agencies.find((agency) => {
      if (agency.agencyId == id && screenSize.width < 768) {
        setListboxDetailsCellphoneOpen({ agency: agency, state: true });
      }
      return agency.agencyId == id;
    });
    setIdActualInfoWindow(id);

    const listBox = document.querySelector(".list-box-container");
    listBox.scrollTop = 0;

    auxAgency && setTopAgency(auxAgency);
    auxAgency && setSelectedAgency(auxAgency);

    return auxAgency;
  };

  const setClientServicesCheck = (newState, code) => {
    const auxClientServices = allClientServicesWithCheck?.map((service) => {
      if (service.code === code) {
        return { ...service, checked: newState };
      }
      return service;
    });
    setAllClientServicesWithCheck(auxClientServices);
  };

  const setPointTypesCheck = (newState, code) => {
    const auxPointTypes = pointTypesWithCheck?.map((pointType) => {
      if (pointType.code === code) {
        return { ...pointType, checked: newState };
      }
      return pointType;
    });
    setPointTypesWithCheck(auxPointTypes);
  };

  const setFilterControllerCheck = (newState, code) => {
    const auxFiltersController = filtersControllerWithCheck?.map((filter) => {
      if (filter.code === code) {
        return { ...filter, checked: newState };
      }
      return filter;
    });
    setFiltersControllerWithCheck(auxFiltersController);
  };
  const setAdditionalServiceCheck = (newState, code) => {
    const auxAdditionalServices = additionalServicesWithCheck?.map(
      (service) => {
        if (service.code === code) {
          return { ...service, checked: newState };
        }
        return service;
      }
    );
    setAdditionalServicesWithCheck(auxAdditionalServices);
  };

  const resetFilters = () => {
    setPointTypesWithCheck(
      pointTypesWithCheck?.map((pointType) => ({
        ...pointType,
        checked: false,
      }))
    );
    setAllClientServicesWithCheck(
      allClientServicesWithCheck?.map((service) => ({
        ...service,
        checked: false,
      }))
    );
    setFiltersControllerWithCheck(
      filtersControllerWithCheck?.map((controller) => ({
        ...controller,
        checked: false,
      }))
    );
    setAdditionalServicesWithCheck(
      additionalServicesWithCheck?.map((service) => ({
        ...service,
        checked: false,
      }))
    );
    setSelectedSwitchButtonValue("option-location");
    setSearchBarText("");
  };

  const callbackSetIdActualInfoWindow = (id) => {
    setIdActualInfoWindow(id);
  };
  const updateCenterAndActualLocation = useCallback((latLng) => {
    setCenter(latLng);
    setActualLocation(latLng);
  }, []);

  return (
    <div className="overflow-y-hidden vh-100 d-flex flex-column ">
      {isSharePopUpOpen && (
        <div className="position-absolute z-index-99999 ">
          <SharePopUp onClose={handleClosePopUp} infoToShare={infoToShare} />
        </div>
      )}
      {filtersController && (
        <SideFilters
          pointTypesArray={pointTypesWithCheck}
          setPointTypesCheck={setPointTypesCheck}
          clientServicesArray={allClientServicesWithCheck}
          setClientServicesCheck={setClientServicesCheck}
          filtersControllerArray={filtersControllerWithCheck}
          setFilterControllerCheck={setFilterControllerCheck}
          otherFiltersArray={additionalServicesWithCheck}
          setOtherFilterCheck={setAdditionalServiceCheck}
          setMenuOpen={setIsFiltersMenuOpened}
          selectedSwitchButtonValue={selectedSwitchButtonValue}
          setSelectedSwitchButtonValue={setSelectedSwitchButtonValue}
          resetFilters={resetFilters}
          isOpen={isFiltersMenuOpened}
        />
      )}
      {
        <ListBoxDetailsCellPhone
          onClickBack={() =>
            setListboxDetailsCellphoneOpen({
              ...listboxDetailsCellphoneOpen,
              state: false,
            })
          }
          point={createPoint(listboxDetailsCellphoneOpen.agency)}
          actualLocation={actualLocation}
          onClickShare={handleOpenPopUp}
          isOpen={listboxDetailsCellphoneOpen.state}
          allAdditionalServices={additionalServices?.additional_services}
        />
      }
      <div className="banner-searchbar-container position-relative">
        {isBannerEnabled && <Banner />}
        <div className={isBannerEnabled && "mt-15px hide-on-cellphone"}>
          <div
            className={
              (!isBannerEnabled && "pt-2") +
              " px-4 pb-2 searchbar-container hide-on-cellphone"
            }
          >
            <div>
              <SearchBar
                className={`position-relative z-index-9999`}
                updateMapPosition={updateCenterAndActualLocation}
                selectedButtonValue={selectedSwitchButtonValue}
                setSelectedButtonValue={setSelectedSwitchButtonValue}
                text={searchBarText}
                setText={setSearchBarText}
                screenSize={screenSize}
              />
            </div>

            <div className={"pb-1 pt-3 " + "hide-on-cellphone"}>
              <Filters
                isPointTypesLoaded={isPointTypesLoaded}
                isAllServicesLoaded={isAllClientServicesLoaded}
                sortedClientServices={allClientServicesWithCheck}
                setClientServicesCheck={setClientServicesCheck}
                pointTypesWithCheck={pointTypesWithCheck}
                setPointTypesCheck={setPointTypesCheck}
                additionalServicesWithCheck={additionalServicesWithCheck}
                setAdditionalServicesCheck={setAdditionalServiceCheck}
                filtersControllerWithCheck={filtersControllerWithCheck}
                setFilterControllerCheck={setFilterControllerCheck}
                isFilterControllerLoaded={isFilterControllerLoaded}
                resetFilters={resetFilters}
                map={map}
                setCenter={setCenter}
                setIsFiltersMenuOpened={setIsFiltersMenuOpened}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`position-relative ${
          isBannerEnabled
            ? `map-and-listbox-container-w-banner`
            : `map-and-listbox-container-w-nobanner`
        }`}
      >
        <MapComponent
          isMarkerShown
          isDataLoaded={isDataLoaded}
          agencies={agencies}
          selectedAgencyId={selectedAgency?.agencyId}
          center={center}
          setCenter={setCenter}
          actualLocation={actualLocation}
          idActualInfoWindow={idActualInfoWindow}
          zoom={zoom}
          handleBluexMarkerClick={handleBluexMarkerClick}
          setIdActualInfoWindow={callbackSetIdActualInfoWindow}
          selectedMarkerSize={selectedMarkerSize}
          actualFilterPointTypeCode={actualFilterPointTypeCode}
          actualFilterClientServiceCode={actualFilterClientService}
          isFilterControllerLoaded={isFilterControllerLoaded}
          filtersController={filtersController}
          setAgenciesInsideBoundaries={setAgenciesInsideBoundaries}
          map={map}
          setMap={setMap}
          setActualGeolocation={() =>
            setActualGeolocation(setActualLocation, setCenter, center)
          }
          searchBarText={searchBarText}
          selectedSwitchButtonValue={selectedSwitchButtonValue}
          additionalServicesArray={additionalServicesWithCheck}
          pointTypesArray={pointTypesWithCheck}
          clientServicesArray={allClientServicesWithCheck}
          filtersControllerArray={filtersControllerWithCheck}
        />
        <div className="p-3 d-flex align-items-center gap-3 hide-on-desktop-and-tablet">
          <div className="flex-grow-0">
            <ButtonFilters
              setIsFilterMenuOpened={setIsFiltersMenuOpened}
              iconSize="32px"
            />
          </div>
          <div className="flex-grow-1">
            <SearchBar
              className={`position-relative z-index-9999`}
              updateMapPosition={updateCenterAndActualLocation}
              selectedButtonValue={selectedSwitchButtonValue}
              setSelectedButtonValue={setSelectedSwitchButtonValue}
              text={searchBarText}
              setText={setSearchBarText}
              screenSize={screenSize}
            />
          </div>
        </div>
        <div
          className={
            (isBannerEnabled
              ? "list-box-container-scroll-w-banner"
              : "list-box-container-scroll-w-nobanner") + " list-box-container "
          }
        >
          {isDataLoaded ? (
            <>
              {topAgency &&
              getFilteredAgenciesV2(
                [topAgency],
                [topAgency],
                null,
                pointTypesWithCheck,
                allClientServicesWithCheck,
                filtersControllerWithCheck,
                additionalServicesWithCheck,
                selectedSwitchButtonValue,
                searchBarText
              ).length > 0
                ? getFilteredAgenciesV2(
                    [topAgency],
                    [topAgency],
                    null,
                    pointTypesWithCheck,
                    allClientServicesWithCheck,
                    filtersControllerWithCheck,
                    additionalServicesWithCheck,
                    selectedSwitchButtonValue,
                    searchBarText
                  )?.map((a, i) => (
                    <div
                      key={i}
                      className={
                        selectedAgency?.agencyId === a.agencyId
                          ? "selected-list-box"
                          : ""
                      }
                    >
                      <ListBox
                        point={createPoint(topAgency)}
                        key={topAgency.agencyId}
                        id={topAgency.agencyId}
                        onClickTitle={handleClickListboxTitle}
                        onClickShare={handleOpenPopUp}
                        actualLocation={
                          actualLocation.lat + "," + actualLocation.lng
                        }
                        allAdditionalServices={
                          additionalServices.additional_services
                        }
                      />
                    </div>
                  ))
                : (isTopAgencyFiltered = true)}

              {agenciesInsideBoundaries &&
              getFilteredAgenciesV2(
                agenciesInsideBoundaries,
                agencies.agencies,
                topAgency,
                pointTypesWithCheck,
                allClientServicesWithCheck,
                filtersControllerWithCheck,
                additionalServicesWithCheck,
                selectedSwitchButtonValue,
                searchBarText
              ).length === 0 &&
              (!topAgency || isTopAgencyFiltered)
                ? getEmptyListbox()
                : agenciesInsideBoundaries &&
                  getFilteredAgenciesV2(
                    agenciesInsideBoundaries,
                    agencies.agencies,
                    topAgency,
                    pointTypesWithCheck,
                    allClientServicesWithCheck,
                    filtersControllerWithCheck,
                    additionalServicesWithCheck,
                    selectedSwitchButtonValue,
                    searchBarText
                  )?.map((agency) => {
                    const point = createPoint(agency);

                    return (
                      <div
                        key={agency.agencyId}
                        className={
                          selectedAgency?.agencyId === agency.agencyId
                            ? "selected-list-box"
                            : ""
                        }
                      >
                        <ListBox
                          point={point}
                          key={agency.agencyId}
                          id={agency.agencyId}
                          onClickTitle={handleClickListboxTitle}
                          onClickShare={handleOpenPopUp}
                          actualLocation={
                            actualLocation.lat + "," + actualLocation.lng
                          }
                          allAdditionalServices={
                            additionalServices?.additional_services
                          }
                        />
                      </div>
                    );
                  })}
            </>
          ) : (
            <>
              <LazyLoadListBox />
              <LazyLoadListBox />
              <LazyLoadListBox />
              <LazyLoadListBox />
              <LazyLoadListBox />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneralContainer;
