import {
  ChevronDown,
  ChevronUp,
  Share2,
  Phone,
  CheckCircle,
} from "feather-icons-react/build/IconComponents";
import React, { useState, Fragment } from "react";
import Schedule from "./Schedule";
const ListBox = ({
  point,
  onClickTitle,
  id,
  onClickShare,
  actualLocation,
  allAdditionalServices,
}) => {
  // toggle content on click
  const [toggle, setToggle] = useState(false);

  const onClickMapsButton = () => {
    window.open(
      `https://www.google.cl/maps/dir/${actualLocation}/${point.address}/`,
      "_blank"
    );
  };
  const RenderSchelude = ({ point }) => {
    return (
      <Fragment>
        {point.other_location_info && (
          <div className="mb-2">
            <span className="fs-12px fw-800 fst-italic">
              Referencia de ubicación:{" "}
            </span>
            <span className="fs-12px fst-italic fw-600">
              {point.other_location_info}
            </span>
          </div>
        )}
        <div className="px-2 pb-2">
          <div className="pb-3">
            <Schedule
              scheduleDetail={point.scheduleDetail}
              is24Hours={point.is24Hours}
            />
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-2">
              <button
                className="btn btn-options w-100 details fs-14px fw-700"
                onClick={onClickMapsButton}
              >
                <img
                  src="/assets/directions-icon.svg"
                  alt="map-icon"
                  className="me-2"
                  style={{ width: "16px", height: "16px" }}
                />
                Cómo llegar
              </button>
              <button
                className="btn btn-options  w-100 details fs-14px fw-700"
                onClick={() =>
                  onClickShare({
                    id: id,
                    title: point.name,
                    address: point.address,
                  })
                }
              >
                <Share2 size={16} className="me-2" />
                Compartir
              </button>
            </div>
            <div>
              {point.phone.isVisible && (
                <button
                  className="btn btn-options-white  w-100 details fs-14px fw-700"
                  onClick={() =>
                    (window.location.href = "tel:" + point.phone.phoneNumber)
                  }
                >
                  <Phone size={16} className="me-2" />
                  Llamar al Punto
                </button>
              )}
            </div>
          </div>
          <div>
            {point.clientServices?.length > 0 && (
              <div className="mb-1 mt-3">
                <span className="fw-800 fs-14px">Servicios disponibles</span>
              </div>
            )}
            {
              <div className="d-flex flex-column gap-1 mb-1">
                {point.clientServices?.map((service) => (
                  <div
                    key={service.id}
                    className="d-flex gap-2 align-items-center"
                  >
                    <CheckCircle className="color-text-skyblue" size={18} />
                    <span className="fs-14px">{service.name}</span>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  };

  const isPointTypeContainsCopec = point.typeAgency.name
    .toString()
    .toLowerCase()
    .includes("copec");

  return (
    <div className={"list-box " + (point.is_opened ? "" : "disabled")}>
      <div
        onClick={() => onClickTitle(id)}
        className="cursor-pointer highlight"
      >
        <div className="list-box-header mb-2">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-start gap-2 align-items-start">
              <div className="icon-container">
                {isPointTypeContainsCopec ? (
                  <img
                    src={
                      process.env.REACT_APP_IMG_URL + "/map_markers/copec-1.png"
                    }
                    alt="store-icon"
                    className="icon-store"
                  />
                ) : (
                  <img
                    src={
                      process.env.REACT_APP_IMG_URL + "/map_markers/b-blue.png"
                    }
                    alt="store-icon"
                    className="icon-store"
                  />
                )}
              </div>
              <span className="text-main">{point.name}</span>
            </div>
            <div className="d-flex justify-content-end  align-items-start">
              <span className="fs-6 fw-800 text-km color-text-orange ps-1">
                a {point.distance}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex gap-2 mb-2">
          <div className="icon-container">
            <img
              src="/assets/marker-icon.svg"
              alt="store-icon"
              className="marker-icon"
            />
          </div>
          <span className="fs-6 fw-500">{point.address}</span>
        </div>

        <div className="d-flex gap-2 mb-2">
          <div className="icon-container">
            <img
              src="/assets/clock-icon.svg"
              alt="clock-icon"
              style={{ width: "24px", height: "24px" }}
            />
          </div>

          <span
            className={`fw-800 fs-6 border-radius-8px ${
              point.is24Hours && "px-2 bg-color-24-7"
            }`}
          >
            {point.is24Hours ? "Abierto 24/7" : point.schedule}
          </span>
        </div>
      </div>
      <div className="d-flex flex-row flex-wrap justify-content-center gap-3 pb-2">
        {point.additionalServices?.map((service) => {
          const matchingService = allAdditionalServices?.find(
            (allAdditionalService) => allAdditionalService.code === service.id
          );
          return (
            <img
              key={service.name}
              src={matchingService?.icon_url}
              alt="dimensions-icon"
              className="dimensions-icon"
              style={{ width: "24px", height: "24px" }}
            />
          );
        })}
      </div>

      {/* toggle content on click */}
      <div
        className="details cursor-pointer"
        onClick={() => setToggle(!toggle)}
      >
        <div className="hide-on-cellphone">
          {toggle ? (
            <div className="fw-800 details-text d-flex justify-content-center color-text-orange">
              <span>Ver menos</span>
              <ChevronUp size={24} className="text-main cursor-pointer" />
            </div>
          ) : (
            <div className="fw-800 details-text d-flex justify-content-center color-text-orange">
              <span>Ver detalles del Punto</span>
              <ChevronDown size={24} className="text-main cursor-pointer " />
            </div>
          )}
        </div>
      </div>
      <div>
        {toggle && <RenderSchelude point={point} />}

        {/* <p className="fw-bolder fs-sm">
          Entrega tus paquetes antes de las 15:20 hrs. y tus encomiendas serán
          retiradas el mismo día
        </p> */}
        <div className="d-flex gap-4 fs-12px justify-content-between fw-700">
          <div className="d-flex align-items-center gap-2">
            <img
              src="/assets/dimensions-icon.svg"
              alt="dimensions-icon"
              className="dimensions-icon"
              style={{ width: "16px", height: "16px" }}
            />
            <span>{point.dimensions}</span>
          </div>
          <div className="d-flex align-items-center gap-2">
            <img
              src="/assets/weight-icon.svg"
              alt="weight-icon"
              className="weight-icon"
              style={{ width: "16px", height: "16px" }}
            />
            <span>{point.weight}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListBox;
